/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="Filter List"><path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="currentColor"/></g></svg>'
      },
    }
  },
  colors: {
    brand_1: {
      name: 'Red',
      value: '#ff0000'
    },
    brand_2: {
      name: 'Blue',
      value: '#0000ff'
    },
    mono_1: {
      name: 'White',
      value: '#fff'
    },
    mono_2: {
      name: 'Black',
      value: '#000'
    },
    error: {
      name: 'Error',
      value: '#cc0000'
    },
    info: {
      name: 'Info',
      value: '#0099cc'
    },
    success: {
      name: 'Success',
      value: '#007e33'
    },
    warning: {
      name: 'Warning',
      value: '#ff8800'
    }
  },
  fonts: {
    font_1: {
      name: 'Serif',
      value: 'Georgia, "Times New Roman", Times, serif'
    },
    font_2: {
      name: 'Sans-serif',
      value: 'Arial, Helvetica, sans-serif'
    },
    font_3: {
      name: 'Modern',
      value: 'Tahoma, Verdana, Geneva, sans-serif'
    },
    font_4: {
      name: 'Monospace',
      value: '"Courier New", Courier, monospace'
    }
  }
}
